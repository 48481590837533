<template>
  <div class="ds-container">
    <div class="ds-header">
      <div class="ds-title">
        Dashboard
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Confira aqui os dados e resumos mais importantes do seu estoque.
        </b-tooltip>
      </div>
    </div>

    <b-row>
      <div v-if="false" class="row">
        <div class="col-12">
          <PendingOutputProducts
            :openBillToReceiveProductModal="openBillToReceiveProductModal"
          />
        </div>
      </div>

      <table class="grid-table">
        <tr>
          <td class="small-card">
            <SimpleCard
              title="Lotes com vencimento próximo"
              :value="closeToExpirations"
              color="var(--orange)"
              :onClick="() => redirectToTab(6, '/estoque/lotes?v=p')"
            />
          </td>
          <td class="small-card" rowspan="2">
            <MonthlyCard
              type="ENTRANCE"
              :api="this.api.getWarehouseDashboardMonthly"
            />
          </td>
          <td class="large-card" rowspan="4">
            <WarehouseEntryOutCard />
          </td>
        </tr>
        <tr>
          <td class="small-card">
            <SimpleCard
              title="Lotes vencidos"
              :value="expiredProducts"
              color="#FBA59D"
              :onClick="() => redirectToTab(6, '/estoque/lotes?v=v')"
            />
          </td>
        </tr>
        <tr>
          <td class="small-card" rowspan="1">
            <SimpleCard
              title="Produtos com estoque baixo"
              :value="lowStock"
              color="var(--light-orange-500)"
              :onClick="() => redirectToTab(1, '/estoque/produtos?p=ls')"
            />
          </td>
          <td class="small-card" rowspan="2">
            <MonthlyCard
              type="OUTPUT"
              :api="this.api.getWarehouseDashboardMonthly"
            />
          </td>
        </tr>
        <tr>
          <td class="small-card">
            <SimpleCard
              title="Variedade de itens cadastrados"
              :value="count"
              color="var(--blue-500)"
              :onClick="() => redirectToTab(1, '/estoque/produtos?')"
            />
          </td>
        </tr>
        <tr>
          <td class="small-card" colspan="2">
            <ProductListCard type="products" />
          </td>
          <td class="small-card">
            <ProductListCard type="ranking" />
          </td>
        </tr>
      </table>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    MonthlyCard: () => import('@/components/Dashboard/MonthlyCard'),
    PendingOutputProducts: () => import('@/components/Dashboard/PendingOutputProducts'),
    ProductListCard: () => import('@/components/Dashboard/ProductListCard'),
    SimpleCard: () => import('@/components/Dashboard/SimpleCard'),
    WarehouseEntryOutCard: () => import('@/components/Dashboard/WarehouseEntryOutCard')
  },
  props: {
    onChangeTab: Function,
    openBillToReceiveProductModal: Function,
  },
  created() {
    this.getExpiredProducts();
    this.getLowStockProducts();
    this.getCloseToExpirations();
    this.getProductCount();
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      closeToExpirations: 0,
      expiredProducts: 0,
      lowStock: 0,
      count: 0,
    }
  },
  methods: {
    getExpiredProducts() {
      this.api.getExpiredProducts(this.clinic.id)
        .then(res => {
          this.expiredProducts = res.data;
        })
        .catch(err => this.$toast.error(err.message))
    },
    getLowStockProducts() {
      this.api.getLowStockProducts(this.clinic.id)
        .then(res => {
          this.lowStock = res.data;
        })
        .catch(err => this.$toast.error(err.message))
    },
    getCloseToExpirations() {
      this.api.getCloseToExpirations(this.clinic.id)
        .then(res => {
          this.closeToExpirations = res.data;
        })
        .catch(err => this.$toast.error(err.message))
    },
    getProductCount() {
      this.api.getProductCount(this.clinic.id)
        .then(res => {
          this.count = res.data;
        })
        .catch(err => this.$toast.error(err.message))
    },
    redirectToTab(tab, path) {
      if (this.$route.path.indexOf(path) === -1){
        this.onChangeTab(tab);
        path && this.$router.push(path, true);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .ds-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
    .icon {
      height: 24px;
      width: 24px;
      stroke: var(--neutral-500);
      margin-left: 5px;
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
    .content {
      max-width: 1400px;
      margin: 0 auto;
    }
    .grid-table {
      width: 100%;
      height: min-content;
    }
    tr {
      width: 100%;
      vertical-align: top;
    }
    td {
      padding: 5px;
    }
    .small-card {
      width: 25% !important;
    }
    .large-card {
      width: 50% !important;
    }
    .row {
      padding: 5px;
    }
  }
  .wh-button {
    margin: 0 0 0 10px;
    border-color: var(--blue-500);
    color: var(--blue-500);
  }
</style>
